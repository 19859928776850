import { el } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import { Button, ButtonToolbar } from 'rsuite'

export function FormulaSaveButton({
  formulaId = '',
  onchnage,
  pracDetails,
  is_order,
  allowSubmitBtn = false
}) {
  const [online, setOnline] = useState(window.navigator.onLine)

  const [allow_checkout, set_allow_checkout] = useState(false)
  const [payment_method, set_payment_method] = useState([])
  const [prac_user_type, set_prac_user_type] = useState('')

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setOnline(window.navigator.onLine)
    }

    window.addEventListener('online', handleOnlineStatusChange)
    window.addEventListener('offline', handleOnlineStatusChange)

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange)
      window.removeEventListener('offline', handleOnlineStatusChange)
    }
  }, [])

  useEffect(() => {
    if (pracDetails && pracDetails.PRAC_CHECKOUT_CONTROL_SETTING) {
      if (pracDetails.PRAC_CHECKOUT_CONTROL_SETTING === 1) {
        set_allow_checkout(true)
      } else {
        set_allow_checkout(false)
      }
    } else {
      set_allow_checkout(false)
    }

    if (pracDetails && pracDetails.payment_method) {
      set_payment_method(pracDetails.payment_method)
    } else {
      set_payment_method([])
    }
    set_prac_user_type(pracDetails ? pracDetails.prac_user_type : '')
  }, [pracDetails])

  if (online && is_order === 'true') {
    return (
      <div className='row'>
        <div
          align='right'
          className='FormulaBuilderSaveButton'
          style={{ marginTop: '4rem', marginBottom: '2rem' }}
        >
          <Button
            color='cyan'
            title='Press to save Formula'
            appearance='primary'
            disabled={allowSubmitBtn}
            onClick={(e) => {
              onchnage('save_order')
            }}
          >
            Save Order
          </Button>
        </div>
      </div>

    )
  }
  if (online) {
    return (
      <div className='row'>
        <div
          align='center'
          className='FormulaBuilderSaveButton'
          style={{ marginTop: '4rem', marginBottom: '2rem' }}
        >
          <ButtonToolbar style={{ float: 'right', marginBottom: '4rem' }}>
            {allow_checkout == true && payment_method.length != 0 ? (
              <>
                <Button
                  color='blue'
                  title='Save Formula and Exit'
                  appearance='primary'
                  disabled={allowSubmitBtn}
                  onClick={(e) => {
                    onchnage('exit')
                  }}
                >
                  Save Formula + Exit
                </Button>
                <Button
                  color='green'
                  title='Save Formula and Continue Editing'
                  appearance='primary'
                  disabled={allowSubmitBtn}
                  onClick={() => onchnage('continue_editing')}
                >
                  Save Formula + Continue Editing
                </Button>
                <Button
                  color='green'
                  title='Save + Review Formula'
                  appearance='primary'
                  disabled={allowSubmitBtn}
                  onClick={(e) => {
                    onchnage('save')
                  }}
                >
                  Save + Review Formula
                </Button>
              </>
            ) : (
              <>
                {prac_user_type != 'student' && (
                  <Button
                    color='cyan'
                    title='Save Formula and Exit'
                    appearance='primary'
                    disabled={allowSubmitBtn}
                    onClick={(e) => {
                      onchnage('exit')
                    }}
                  >
                    Save Formula + Exit
                  </Button>
                )}

                <Button
                  color='blue'
                  title='Save Formula and Continue Editing'
                  appearance='primary'
                  disabled={allowSubmitBtn}
                  onClick={() => onchnage('continue_editing')}
                >
                  Save Formula + Continue Editing
                </Button>
                <Button
                  color='green'
                  title='Save + Review Formula'
                  appearance='primary'
                  disabled={allowSubmitBtn}
                  onClick={(e) => {
                    onchnage('save')
                  }}
                >
                  Save + Review Formula
                </Button>
              </>
            )}
          </ButtonToolbar>
        </div>
      </div>
    )
  } else {
    return (
      <div className='row'>
        <div align='center' className='FormulaBuilderSaveButton' style={{ marginTop: '4rem', marginBottom: '2rem' }}>
          <ButtonToolbar style={{ float: 'right', marginBottom: '4rem' }}>

            {allow_checkout == true && payment_method.length > 0 ? (
              <>
                <Button
                  color='blue'
                  title='Save Formula and Exit'
                  appearance='primary'
                  disabled={allowSubmitBtn}
                  onClick={(e) => {
                    onchnage('exit')
                  }}
                >
                  Save Formula + Exit
                </Button>
                <Button
                  color='green'
                  title='Save Formula and Continue Editing'
                  appearance='primary'
                  disabled={allowSubmitBtn}
                  onClick={() => onchnage('continue_editing')}
                >
                  Save Formula + Continue Editing
                </Button>
                <Button
                  color='green'
                  title='Save + Add to Cart'
                  appearance='primary'
                  disabled={allowSubmitBtn}
                  onClick={(e) => {
                    onchnage('cart')
                  }}
                >
                  Save + Add to Cart
                </Button>
              </>
            ) : (
              <>
                {prac_user_type != 'student' && (
                  <Button
                    color='cyan'
                    title='Save Formula and Exit'
                    appearance='primary'
                    disabled={allowSubmitBtn}
                    onClick={(e) => {
                      onchnage('exit')
                    }}
                  >
                    Save Formula + Exit
                  </Button>
                )}

                <Button
                  color='blue'
                  title='Save Formula and Continue Editing'
                  appearance='primary'
                  disabled={allowSubmitBtn}
                  onClick={() => onchnage('continue_editing')}
                >
                  Save Formula + Continue Editing
                </Button>
                <Button
                  color='green'
                  title='Submit to Admin'
                  appearance='primary'
                  disabled={allowSubmitBtn}
                  onClick={(e) => {
                    onchnage('order')
                  }}
                >
                  Submit to Admin
                </Button>
                <Button
                  color='green'
                  title='Press to Add to Cart'
                  appearance='primary'
                  disabled={allowSubmitBtn}
                  onClick={(e) => {
                    onchnage('cart')
                  }}
                >
                  Add to Cart
                </Button>
              </>
            )}
          </ButtonToolbar>
        </div>
      </div>
    )
  }
}
