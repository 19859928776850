import {
    SUMMARY_FAILED,
    SUMMARY_SUCCESS,
    SUMMARY_REQUEST,
} from "./summary.state";


const Model = {
    data: []
}

export const summaryReducer = (state = Model, action) => {
    switch (action.type) {
        case SUMMARY_REQUEST: return {
            ...state,
            data: []
        }

        case SUMMARY_SUCCESS: return {
            ...state,
            data: action.payload
        }
        case SUMMARY_FAILED: return {
            ...state,
            data: []
        }

        default: return state
    }
}
