import React, { useEffect, useState } from 'react'

const FormulaBuilderSummarySection = ({
  currency,
  formulaSummary,
  display_name,
  allowEnterQty,
  allowRawQty,
}) => {
  return (
    <>
      <div className='FormulaBuilderSummarySection'>
        {formulaSummary && Object.keys(formulaSummary).length > 0 && (
          <h2 className='title mt-5'>{display_name}</h2>
        )}

        <div className='col-4'>
          {/*<pre>{JSON.stringify(formulaSummary, null, 2)}</pre>*/}
          {formulaSummary &&
            Object.keys(formulaSummary).map((ingredientType, measurement) => (
              <table
                className='table TableMainadd table-bordered'
                style={{ overflowX: 'unset' }}
              >
                {/*console.log('formulaSummary',ingredientType,measurement)*/}
                <thead>
                  <tr>
                    <th className='column' style={{ width: 'auto' }}>
                      {ingredientType}
                    </th>
                    {Object.keys(formulaSummary[ingredientType]).map(
                      (measurement) => (
                        <th style={{ width: '100px' }} key={measurement}>
                          {measurement}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody
                  className={`tbody ${ingredientType}`}
                  key={ingredientType}
                >
                  {formulaSummary[ingredientType].quantitySelected !== 0 && (
                    <>
                      {/* Ingredients Quantity Row */}
                      <tr>
                        <td className='column'>Number of Ingredients</td>
                        {Object.keys(formulaSummary[ingredientType]).map(
                          (measurement) =>
                            measurement !== 'quantitySelected' && (
                              <React.Fragment key={measurement}>
                                <td>
                                  {
                                    formulaSummary[ingredientType][measurement]
                                      .quantitySelected
                                  }
                                </td>
                              </React.Fragment>
                            )
                        )}
                      </tr>
                      {/*Enter Qty*/}
                      {allowEnterQty && (
                        <tr>
                          {Object.keys(formulaSummary[ingredientType]).map(
                            (measurement, index) =>
                              measurement !== 'quantitySelected' && (
                                <React.Fragment key={measurement}>
                                  {index %
                                    Object.keys(formulaSummary[ingredientType])
                                      .length ===
                                    0 && (
                                      <td className='column'>
                                        Total{' '}
                                        {
                                          formulaSummary[ingredientType][
                                            measurement
                                          ].qtyValueColumnName
                                        }{' '}
                                        Enter
                                      </td>
                                    )}
                                  <td>
                                    {formulaSummary[ingredientType][
                                      measurement
                                    ].totalQty.toFixed(2)}
                                  </td>
                                </React.Fragment>
                              )
                          )}
                        </tr>
                      )}

                      {/*Raw Qty*/}
                      {allowRawQty && (
                        <tr>
                          {Object.keys(formulaSummary[ingredientType]).map(
                            (measurement, index) =>
                              measurement !== 'quantitySelected' && (
                                <React.Fragment key={measurement}>
                                  {index %
                                    Object.keys(formulaSummary[ingredientType])
                                      .length ===
                                    0 && (
                                      <td className='column'>
                                        Total{' '}
                                        {
                                          formulaSummary[ingredientType][
                                            measurement
                                          ].rawPerDayColumnName
                                        }{' '}
                                        Raw
                                      </td>
                                    )}
                                  <td>
                                    {formulaSummary[ingredientType][
                                      measurement
                                    ].rawPerDay.toFixed(2)}
                                  </td>
                                </React.Fragment>
                              )
                          )}
                        </tr>
                      )}

                      <tr>
                        {Object.keys(formulaSummary[ingredientType]).map(
                          (measurement, index) =>
                            measurement !== 'quantitySelected' && (
                              <React.Fragment key={measurement}>
                                {index %
                                  Object.keys(formulaSummary[ingredientType])
                                    .length ===
                                  0 && (
                                    <td
                                      key={measurement}
                                      style={{ textTransform: 'capitalize' }}
                                    >
                                      {Object.keys(formulaSummary[ingredientType])
                                        .length == 1
                                        ? 'Total ' +
                                        formulaSummary[ingredientType][
                                          measurement
                                        ].measurementType
                                        : 'Total Weight/Volume'}
                                    </td>
                                  )}
                                <td>
                                  {Math.round(
                                    formulaSummary[ingredientType][measurement]
                                      .totalQtyReceived
                                  )}{' '}
                                  {measurement}
                                </td>
                              </React.Fragment>
                            )
                        )}
                      </tr>
                      {/* <tr>
                                                {Object.keys(formulaSummary[ingredientType]).map((measurement) => (
                                                    <td key={measurement}>Total {ingredientType}{measurement} weight</td>
                                                ))}                                                {Object.keys(formulaSummary[ingredientType]).map((measurement) => (
                                                    measurement !== 'quantitySelected' &&
                                                    <td key={measurement}>{formulaSummary[ingredientType][measurement].totalQtyReceived}</td>
                                                ))}
                                            </tr> */}
                      {/* <tr>
                                                <td className='col-4'>Price</td>
                                                {Object.keys(formulaSummary[ingredientType]).map((measurement) => (
                                                    measurement !== 'quantitySelected' && <td key={measurement}>{formulaSummary[ingredientType][measurement].price}</td>
                                                ))}
                                            </tr> */}
                      <tr>
                        <td className='column'>Subtotal</td>
                        {Object.keys(formulaSummary[ingredientType]).map(
                          (measurement) =>
                            measurement !== 'quantitySelected' && (
                              <td key={measurement}>
                                {currency}
                                {formulaSummary[ingredientType][
                                  measurement
                                ].subtotal.toFixed(2)}
                              </td>
                            )
                        )}
                      </tr>
                      {/* Repeat similar rows for other data fields like rawPerDay, totalQtyReceived, price, subtotal */}
                    </>
                  )}
                </tbody>
              </table>
            ))}
        </div>
        <div style={{ marginBottom: '30px' }}></div>
      </div>
    </>
  )
}

export default FormulaBuilderSummarySection
