// text: text that want show in message
// status: status its means which type of message like: error,suceess,warning
// key: its means store message with key what happen then its message store
// type: its means message you want to add or delete

export const customNotificationMessage = ({ text, status, key, fun, type }) => {
    const newMessage = { text, status }
    // setMessages((prevMessages) => [...prevMessages, newMessage])
    if (type === "add") {
        fun((prevMessages) => {
            return {
                ...prevMessages,
                [key]: newMessage
            }
        })
    } else {
        fun((prevMessages) => {
            const { [key]: deletedMessage, ...restMessages } = prevMessages;
            return restMessages;
        });
    }

    // Automatically remove the message after a specific time based on its status
    let removeTimeout = 2000 // Default: 5 seconds
    if (status === 'warning' || status === 'error') {
        removeTimeout = 60000 // Set to 60 seconds for 'warning' and 'error' messages
    }
    setTimeout(() => {
        fun((prevMessages) => {
            const { ["common"]: deletedMessage, ...restMessages } = prevMessages;
            return restMessages;
        });
    }, removeTimeout)

}