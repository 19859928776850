import React, { useEffect, useState } from 'react'
import DOMPurify from 'dompurify'

export function NoInternetPopup() {
  const [online, setOnline] = useState(window.navigator.onLine)

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setOnline(window.navigator.onLine)
    }

    window.addEventListener('online', handleOnlineStatusChange)
    window.addEventListener('offline', handleOnlineStatusChange)

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange)
      window.removeEventListener('offline', handleOnlineStatusChange)
    }
  }, [])

  if (online) {
    return null // No need to show the popup if online
  }

  return (
    <div className='no-internet-popup' align='center'>
      <span>⚠ Offline</span>
    </div>
  )
}

// convert text to html with special characters

export function TextToHTML(text) {
  const sanitizedHTML = DOMPurify.sanitize(text)
  const wrapper = document.createElement('div')
  wrapper.innerHTML = sanitizedHTML
  var html = wrapper.innerHTML
  console.log('TextToHTML', html, sanitizedHTML)
  return <div dangerouslySetInnerHTML={{ __html: html }} />
}
