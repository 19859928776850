import React, { useEffect, useState } from 'react'
import { Modal, Placeholder, Loader } from 'rsuite'
import VisibleIcon from '@rsuite/icons/Visible'
import BlockIcon from '@rsuite/icons/Block'
import {
  getIngredientViewDeatils,
  ApiFormulaBulderIngredientDetails,
} from '../../../services/api/api_formula_builder'

import {
  SelectPicker,
  Divider,
  Table,
  Toggle,
  TagPicker,
  Button,
  Pagination,
} from 'rsuite'

import PlusIcon from '@rsuite/icons/Plus'
import { getSearchIngredients } from '../../../services/api/api_formula_builder'
import { APIConfig } from '../../../apiConfig'
const { BASEURL } = APIConfig
const { Column, HeaderCell, Cell } = Table


export default function IngredientView({
  rowData = [],
  formulaBuilderId,
  pracId,
  nameTypes = [],
  selectedIngredients = [],
  updateAddedIngredients,
  tableColumns = [],
  searchPopup
}) {
  const ingredient_id = rowData?.ingredient_id
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [size] = useState('full')
  const [similarHerbs, setSimilarHerbs] = useState('')
  const [similarIngredient, setSimilarIngredient] = useState([])
  const ingredientIds = selectedIngredients
    .map((item) => item.ingredient_id)
    .filter(
      (ingredientId) =>
        ingredientId !== undefined &&
        ingredientId !== null &&
        ingredientId != ''
    )

  const [iframeloading, setIframeLoading] = useState(true);

  const default_name = 'all_names'
  if (!nameTypes.includes(default_name)) {
    nameTypes.push(default_name)
  }

  const modifiedTableColumns = tableColumns.map((column) => ({
    ...column,
    id: column.id.replace('formula_dosage_column_', ''),
  }))

  const TableNameColumns = modifiedTableColumns.filter((column) =>
    nameTypes.includes(column.id)
  )
  if (!TableNameColumns) {
    const TableNameColumns = modifiedTableColumns.filter((column) =>
      nameTypes.includes('all_names')
    )
  }

  const newColumns = TableNameColumns.map((column) => {
    // Add your additional fields here
    return {
      ...column,
      label: column.name,
      resizable: true,
      key: column.id,
      fixed: '',
      // Add more fields as needed
    }
  })

  const [addedIngredients, setAddedIngredients] = useState(ingredientIds)

  const handleOpen = () => {
    setOpen(true)
    searchPopup(true)
  }
  useEffect(() => {
    if (addedIngredients && addedIngredients.length != 0) {
      updateAddedIngredients(addedIngredients)
    }

  }, [addedIngredients])

  const handleClose = () => {
    setOpen(false)
    searchPopup(false)
  }

  useEffect(() => {
    if (ingredient_id && open == true) {
      //alert([open, ingredient_id])
      const ingredientIds = selectedIngredients
        .map((item) => item.ingredient_id)
        .filter(
          (ingredientId) =>
            ingredientId !== undefined &&
            ingredientId !== null &&
            ingredientId != ''
        )
      setAddedIngredients(ingredientIds)
      getDetails()
    }
  }, [open])

  const [receivedCartContent, setReceivedContent] = useState('')

  useEffect(() => {
    if (ingredient_id && open == true) {
      //alert(similarHerbs)
      var similarHerbId = similarHerbs ? JSON.parse(similarHerbs) : [] // udated
      getSimilarIngredients(similarHerbId)
    }
  }, [similarHerbs])

  const getSimilarIngredients = async (herbs) => {
    var herbArr = []
    if (herbs) {
      const arr = []
      herbs.map((item) => {
        arr.push(item.herb_id)
      })

      const promises = arr.map(async (herb) => {
        try {
          // Make an API call or some processing for each herb
          const response = await getIngredientDetails(herb)
          // Handle the response
          if (response.length !== 0) {
            herbArr.push(response)
          }
        } catch (error) {
          // Handle errors from the API call
          //console.error('Error fetching ingredient details:', error)
        }
      })

      // Wait for all promises to resolve before updating state
      await Promise.all(promises)
      setSimilarIngredient(herbArr)
    }
  }

  const getDetails = async () => {
    setLoading(true)
    try {
      const response = await getIngredientViewDeatils({
        pracId,
        formulaBuilderId,
        ingredient_id,
      })
      setData(response || [])

      // Now you can call getSimilarIngredients with response.similarformula
      if (response && response.similarformula) {
        setSimilarHerbs(response.similarformula)
        //await getSimilarIngredients(response.similarformula)
      }
    } catch (error) {
      // Handle errors when fetching data
      //console.error('Error fetching data:', error)
    }
    setLoading(false)
  }
  const getIngredientDetails = async (Herb_Id) => {
    const response = await ApiFormulaBulderIngredientDetails({
      formulaBuilderId,
      Herb_Id,
      pracId,
    })

    const herbData = await response
    return herbData
  }

  const renderContent = () => {
    // return <pre>{JSON.stringify(similarIngredient, null, 2)}</pre>
    if (!ingredient_id) {
      return (
        <div
          style={{
            color: 'red',
            margin: '1rem',
            textAlign: 'center',
            fontSize: 30,
          }}
        >
          <BlockIcon />
          <span> Ingredient not found.</span>
        </div>
      )
    }

    if (loading) {
      return (
        <>
          <Placeholder.Graph active />
          <Loader
            size='md'
            backdrop
            speed='slow'
            vertical
            center
            content='loading...'
          />
        </>
      )
    }

    if (data.length === 0) {
      return (
        <div
          style={{
            color: 'red',
            margin: '1rem',
            textAlign: 'center',
            fontSize: 30,
          }}
        >
          <span> Something went wrong.</span>
        </div>
      )
    }

    return (
      <>
        <div className='col-6 nameDiv'>
          {nameTypes
            .filter((name) => data[name] !== '') // Filter out names with empty data
            .map((name, index) => (
              <div className='row' key={index}>
                <span className='col-4' style={{ textTransform: 'capitalize' }}>
                  <b>{name}</b>
                </span>
                <span className='col-4'>{data[name]}</span>
              </div>
            ))}
        </div>
        <div className='col-4'></div>
      </>
    )
  }

  const handleAddIngredient = (ingredient) => {
    if (
      !addedIngredients.some((addedIngredient) => addedIngredient == ingredient)
    ) {
      setAddedIngredients((prevIngredients) => [...prevIngredients, ingredient])
    }
  }
  const isIngredientAdded = (ingredient) => {
    let idExists = false
    for (let i = 0; i < addedIngredients.length; i++) {
      if (addedIngredients[i] == ingredient) {
        idExists = true
        break // Exit the loop once a match is found
      }
    }
    return idExists
  }

  const renderSimilarIngredients = () => {
    if (!similarIngredient || similarIngredient.length === 0) {
      return <></>
    }
    return (
      <div className='col-12 similaIngredients' style={{ margin: '1rem 0px' }}>
        <h4 style={{ marginLeft: '25px', }}>Similar Ingredients</h4>
        <table
          className='table table-bordered '
          style={{
            width: '95%',
            marginLeft: '25px',
            marginTop: '1rem',
          }}
        >
          <thead className='thead-dark'>
            <tr>
              {newColumns.map((column, index) =>
                similarIngredient.some(
                  (ingredient) => ingredient[column.key] !== ''
                ) ? (
                  <th key={index} style={{ textTransform: 'capitalize' }}>
                    {column.name}
                  </th>
                ) : null
              )}
              <th
                colSpan={newColumns.length - 1}
                style={{ textAlign: 'center' }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {similarIngredient.map((ingredient, index) => (
              <tr key={index}>
                {newColumns.map(
                  (column, columnIndex) =>
                    ingredient[column.key] !== '' && (
                      <td key={columnIndex}>
                        {column.name === 'All Names'
                          ? ingredient['default_herb_type']
                          : ingredient[column.key]}
                      </td>
                    )
                )}
                <td style={{ textAlign: 'center' }}>
                  {isIngredientAdded(ingredient.id) ? (
                    <span>
                      <svg width='24' height='24' viewBox='0 0 24 24'>
                        <path
                          d='M6.293 9.293l-1.414 1.414 5.293 5.293 9-9-1.414-1.414-7.586 7.586z'
                          fill='#00A000'
                        />
                      </svg>
                      Added
                    </span>
                  ) : (
                    <>
                      <Button
                        size='sm'
                        color='blue'
                        appearance='ghost'
                        onClick={() => handleAddIngredient(ingredient.id)}
                      >
                        <PlusIcon></PlusIcon> Add to Formula
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <pre>{JSON.stringify(similarIngredient, null, 2)}</pre> */}
      </div>
    )
  }
  const renderIframeM = (src) => {
    return (
      <>
        <iframe
          id='MyIframe'
          src={src}
          width='100%'
          height='100%'
          frameBorder='0'
          allowFullScreen
        >loading...</iframe>
      </>
    )
  }

  const renderIframe = (src) => {
    const handleLoad = () => {
      setIframeLoading(false); // Hide loading message when iframe content loads
    };

    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {iframeloading && (
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            Loading...
          </div>
        )}
        <iframe
          id='MyIframe'
          src={src}
          width='100%'
          height='100%'
          frameBorder='0'
          allowFullScreen
          onLoad={handleLoad} // Detect when iframe content loads
          style={{ display: loading ? 'none' : 'block' }}
        />
      </div>
    );
  };

  if (
    rowData.length == 0 ||
    rowData.ingredient_id == '' ||
    rowData.ingredient_id == null ||
    rowData.ingredient_id == undefined
  ) {
    return null
  }

  return (
    <>
      <button
        row={rowData.id}
        type='button'
        style={{ backgroundColor: '#fff0' }}
        className='view_formula_dosage_column_action'
        onClick={handleOpen}
      >
        <VisibleIcon style={{ fontSize: 20 }} />
      </button>
      {open == true && (
        <Modal
          backdrop='static'
          overflow={true}
          size={size}
          open={open}
          onClose={handleClose}
          autoFocus
        >
          {/* <Modal.Header>
             <Modal.Title>
              <b>{rowData.formula_dosage_column_all_names}</b>
            </Modal.Title> 
           
          </Modal.Header> */}

          <Modal.Body>
            {/* {renderContent()} */}
            <Button className='rs-modal-header-close' style={{ width: 'max-content', position: 'absolute', right: '1rem', zIndex: 99999 }} onClick={handleClose}>Close</Button>
            {renderSimilarIngredients()}

            {renderIframe(
              BASEURL +
              '?page=details&' +
              'id=' +
              ingredient_id +
              '&headerNavFooter=apiDevNoHeader'
            )}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}
    </>
  )
}
