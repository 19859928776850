import axios from 'axios'
import { APIConfig, APIHeaders } from '../../apiConfig'

const { APIURL, DISPENSARY_TOKEN, PRAC_ID } = APIConfig

// get formula builder data
export const ApiFormulaBuilderDetails = async (props) => {
  //console.log('ApiFormulaBuilderDetails START', APIURL, DISPENSARY_TOKEN, props)
  let response
  const userData = {
    response_type: 'all',
    dispensary_token: DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    ingredient_id: props.formulaBuilderId,
  }
  // console.log('ApiFormulaBuilderDetails send data', userData, 'userData')
  try {
    response = await axios.post(APIURL, userData, { headers: APIHeaders })
  } catch (e) {
    // catch error
    throw new Error(e.message)
  }
  // return JSON.parse(response?.data.results.general_section) // or set initial value
  //console.log(response.data)
  return response?.data.results // or set initial value
}

// get Formula Builder -> Dosage Inputs
export const ApiFormulaBuilderDosagesDetails = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'getDosageInputs',
    dispensary_token: DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    ingredient_id: props.formulaBuilderId,
    // formula_t: Formuladata || DosageData
    formula_t: props.formulaDosagesType || '',
    ingredient_selection_type: props.ingredient_selection_type,
  }
  //console.log('dosagesApiData', getDosageInputsResponseObject)
  try {
    response = await axios.post(APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    // catch error
    throw new Error(e.message)
  }
  return response?.data.results // or set initial value
}

export const ApiFormulaBulderIngredientDetails = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'getIngredientDetailsUsingId',
    dispensary_token: DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    herb_id: props.Herb_Id,
    ingredient_id: props.formulaBuilderId,
  }

  try {
    response = await axios.post(APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results // or set initial value
}

export const ApiFormulaBuilderList = async (props) => {
  //console.log('ApiFormulaBuilderDetails START', APIURL, DISPENSARY_TOKEN, props)
  let response

  const userData = {
    response_type: 'getFormulaBuilderList',
    dispensary_token: DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
  }
  // console.log('ApiFormulaBuilderDetails send data', userData, 'userData')
  try {
    response = await axios.post(APIURL, userData, {
      headers: APIHeaders,
    })
  } catch (e) {
    // catch error
    throw new Error(e.message)
  }
  // return JSON.parse(response?.data.results.general_section) // or set initial value
  //console.log(response.data)
  return response?.data.results // or set initial value
}
export const ApiVerifyPrac = async (props) => {
  //console.log('ApiFormulaBuilderDetails START', APIURL, DISPENSARY_TOKEN, props)
  let response

  const userData = {
    response_type: 'verifyPrac',
    dispensary_token: DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
  }
  // console.log('ApiFormulaBuilderDetails send data', userData, 'userData')
  try {
    response = await axios.post(APIURL, userData, { headers: APIHeaders })
  } catch (e) {
    // catch error
    throw new Error(e.message)
  }
  // return JSON.parse(response?.data.results.general_section) // or set initial value
  //console.log(response.data)
  return response?.data.results // or set initial value
}

export const ApiFormulaBulderIngredientLists = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'getFormulaIngredientsList',
    dispensary_token: DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    //herb_id: props.Herb_Id,
    ingredient_id: props.formulaBuilderId,
    ingredient_selection_type: props.ingredient_selection_type,
    allowed_ingredients: props.allowed_ingredients,
  }

  try {
    response = await axios.post(APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response ? response.data.results : [] // or set initial value
}

export const ApiFormulaDetails = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'getFormulaDetails',
    dispensary_token: DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    herb_id: props.formulaId,
    ingredient_id: props.formulaBuilderId,
    is_order: props.is_order,
  }

  try {
    response = await axios.post(APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || null
}
export const ApiSaveFormulaDetails = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'saveFormulaDetails',
    dispensary_token: DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    herb_id: props.formulaId,
    ingredient_id: props.formulaBuilderId,
    form: props.formData,
    is_order: props.is_order,
  }
  console.log(getDosageInputsResponseObject)
  try {
    response = await axios.post(APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || null
}
export const ApiFormulaIngredientTabData = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'ingredientTabData',
    dispensary_token: DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    formula_t: props.formulaDosagesType,
    ingredient_id: props.formulaBuilderId,
    patient_id: props.patient_id,
  }

  try {
    response = await axios.post(APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || null
}

export const getThisFormulaIngredients = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'ingredientTabData',
    dispensary_token: DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    ingredient_id: props.thisFormula,
  }

  try {
    response = await axios.post(APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || null
}

export const getSearchIngredients = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'searchIngredients',
    dispensary_token: DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    formula_builder_id: props.formula_builder_id,
    ingredient_selection_type: props.ingredient_selection_type,
    allowed_ingredients: props.allowed_ingredients,
    //excludes_ingredients: props.excludes_ingredients,
    category: props.selectedCategory,
    channel: props.selectedChannel,
    flavour: props.selectedFlavor,
    symptom: props.selectedSymptoms,
    action: props.selectedActions,
    condition: props.selectedConditions,
    attributes: props.selectedAttributes,
    search: props.search,
  }

  try {
    response = await axios.post(APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || []
}

export const getIngredientViewDeatils = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'ingredientView',
    dispensary_token: DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    ingredient_id: props.ingredient_id,
  }

  try {
    response = await axios.post(APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || null
}

export const ApiFormulaExtraData = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'formula_extra_data',
    dispensary_token: DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
  }

  try {
    response = await axios.post(APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results || []
}

export const ApiFormulaBulderMultipleIngredientDetails = async (props) => {
  let response

  const getDosageInputsResponseObject = {
    response_type: 'getMultipleIngredientDetailsUsingId',
    dispensary_token: DISPENSARY_TOKEN,
    practitioner_id: props.pracId,
    herb_id: props.Herb_Id,
    ingredient_id: props.formulaBuilderId,
  }

  try {
    response = await axios.post(APIURL, getDosageInputsResponseObject, {
      headers: APIHeaders,
    })
  } catch (e) {
    throw new Error(e.message)
  }
  return response?.data.results // or set initial value
}
