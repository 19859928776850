
import { PercentageReducer } from "./components/FormulaBuilder/sections/percentageIssue/percentageIssue.reducer";
import { summaryReducer } from "./components/FormulaBuilder/FormulaBuilderSummarySection/summary.reducer";

import { createStore, applyMiddleware, combineReducers } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
const middlewares = applyMiddleware(
    // logger,
    thunk
);
const root = combineReducers({
    PercentageReducer,
    summaryReducer
});

const storage = createStore(root, {}, middlewares);
export default storage;
